import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"



const firebaseConfig = {
    apiKey: "AIzaSyD6Y9htFDmZw42SaSy0dcXZK0E5at2kYrI",
    authDomain: "ypages-61a6e.firebaseapp.com",
    projectId: "ypages-61a6e",
    storageBucket: "ypages-61a6e.appspot.com",
    messagingSenderId: "928820001491",
    appId: "1:928820001491:web:985419053582e452b7f226",
    measurementId: "G-90GKFCEPK5"
}

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)
export { db, auth, storage }
