import React, { useState, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import { Modal, Button } from 'react-bootstrap';

const MapModal = ({ isOpen, onClose, onSubmit, formData, setFormData }) => {
  const mapRef = useRef(null);
  const [gpsCoordinates, setGPSCoordinates] = useState({ lat: null, lng: null });
  const [address, setAddress] = useState('');
  const [addressCoordinates, setAddressCoordinates] = useState({ lat: null, lng: null });
  const [showAddressInput, setShowAddressInput] = useState(false);

  const handleGPSClick = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setGPSCoordinates({ lat: latitude, lng: longitude });
        setAddress('');
        setAddressCoordinates({ lat: null, lng: null });
        setShowAddressInput(false);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleFinalSubmit = () => {
    const coordinates = {
      latitude: gpsCoordinates.lat || addressCoordinates.lat,
      longitude: gpsCoordinates.lng || addressCoordinates.lng
    };

    setFormData(prevFormData => ({
      ...prevFormData,
      coordinates
    }));

    onClose(); // Close the modal after submitting
  };

  const handleAddressSearch = () => {
    setShowAddressInput(true);
  };

  const handleSearchAddressSubmit = async () => {
    if (address.trim() === '') {
      return;
    }

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=AIzaSyBqpUkx5TifGLr_0bmEt48cjkhhJ8a1onU`
      );
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        setAddressCoordinates({ lat, lng });
        setGPSCoordinates({ lat: null, lng: null });
      }
      setShowAddressInput(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    setGPSCoordinates({ lat: null, lng: null });
    setAddress('');
    setAddressCoordinates({ lat: null, lng: null });
    setShowAddressInput(false);
    onClose();
  };

  return (
    <Modal show={isOpen} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Location Selection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LoadScript
          googleMapsApiKey="AIzaSyBqpUkx5TifGLr_0bmEt48cjkhhJ8a1onU"
          libraries={['places']}
        >
          <GoogleMap
            ref={mapRef}
            center={
              gpsCoordinates.lat !== null
                ? gpsCoordinates
                : addressCoordinates
            }
            zoom={15}
            onLoad={() => {}}
            mapContainerStyle={{
              width: '100%',
              height: '400px'
            }}
          >
            {gpsCoordinates.lat !== null && (
              <Marker
                position={{ lat: gpsCoordinates.lat, lng: gpsCoordinates.lng }}
                draggable
                onDragEnd={(e) => {
                  const { latLng } = e;
                  const lat = latLng.lat();
                  const lng = latLng.lng();

                  setGPSCoordinates({ lat, lng });
                  setAddress('');
                  setAddressCoordinates({ lat: null, lng: null });
                }}
              />
            )}

            {addressCoordinates.lat !== null && (
              <Marker
                position={{
                  lat: addressCoordinates.lat,
                  lng: addressCoordinates.lng
                }}
                draggable
                onDragEnd={(e) => {
                  const { latLng } = e;
                  const lat = latLng.lat();
                  const lng = latLng.lng();

                  setAddressCoordinates({ lat, lng });
                  setGPSCoordinates({ lat: null, lng: null });
                }}
              />
            )}

            <StandaloneSearchBox
              onLoad={() => {}}
              onPlacesChanged={() => {}}
            >
              <input
                type="text"
                placeholder="Search for an address"
                style={{
                  boxSizing: 'border-box',
                  border: '1px solid transparent',
                  width: '100%',
                  height: '32px',
                  padding: '0 12px',
                  borderRadius: '3px'
                }}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </StandaloneSearchBox>
          </GoogleMap>
        </LoadScript>

        <Button variant="light" onClick={handleGPSClick} block>
          Use GPS Location
        </Button>
        <Button variant="light" onClick={handleAddressSearch} block>
          Use/Search Address
        </Button>

        <Modal show={showAddressInput} onHide={() => setShowAddressInput(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="text"
              placeholder="Enter address"
              className="form-control"
              value={address}
              onChange={e => setAddress(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddressInput(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSearchAddressSubmit}>
              Search
            </Button>
          </Modal.Footer>
        </Modal>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleFinalSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MapModal;
