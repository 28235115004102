import './App.css';
import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import ExePage from './pages/ExePage';
import { Container } from '@mui/material';
import SignUpForm from './components/SignUpForm';
import Home from './pages/Home';
import { UserAuthContextProvider } from './Context/UserAuthContext';
import Payment from './pages/Payment';
import Share from './pages/Share';
import Logo from './Images/bgRem.png'
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './components/firebase';
import AdminPanel from './pages/Admin';

function App() {
  const [user, setUser] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user);
        const { uid } = user;
        setUser(true);
        console.log("uid >>>>>", uid)
      } else {
        setUser(false);
      }
    })
  }, []);

  return (
    <div>
      <div style={{ position: 'absolute', display: 'flex', alignContent: 'center', alignItems: 'center', width: '100%' }}>
        <Link to="/ " style={{ display: 'flex', textDecoration: 'none', alignItems: 'center' }}>
          <img src={Logo} height={100} width={100} alt="" />
          <h4 className='logoText'>3Pages</h4>
        </Link>

        <div>

          {/* {user || 
                <button class="btn" onClick={()=>{navigate}}>Sign In</button>
              } */}
        </div>
      </div>

      <UserAuthContextProvider>
        <Routes>
          <Route path='/' element={<Home />} />
          {/* <Route path='/seo137613' element={<AdminPanel />} /> */}
          {/* <Route path='/seoAuth' element={<AdminPanel />} /> */}
          <Route path='/executive-form' element={<ExePage />} />
          <Route path='/signup' element={<SignUpForm />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/share' element={<Share />} />
        </Routes>
      </UserAuthContextProvider>

      <Routes>
        {/* AdminPanel route is outside of UserAuthContextProvider */}
        <Route path='/seoAuth' element={<AdminPanel />} />
      </Routes>

      {user &&
        <div style={{ textAlign: 'center', background: 'rgb(0,0,0,0.8)', padding: '30px 0' }}>

          <button class="btn btn-danger" style={{ fontSize: '0.9rem', justifyContent: 'center', borderRadius: '18px', }} onClick={async () => { await auth.signOut(); localStorage.removeItem('formData'); navigate("/") }}>Sign Out</button>

          <button class="btn btn-success" style={{ fontSize: '0.9rem', justifyContent: 'center', borderRadius: '18px', margin: '0px 20px' }} onClick={async () => { navigate("/seoAuth") }}>SEO Panel</button>

          {/* <ExePage/> */}

        </div>
      }
    </div>
  );
}

export default App;
