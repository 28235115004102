import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { TextareaAutosize } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';


import { db, storage, } from '../components/firebase'


import { getStorage, ref, getDownloadURL, uploadBytesResumable, uploadBytes, listAll } from "firebase/storage";
import { addDoc, collection, setDoc, doc, getDoc, getDocs } from 'firebase/firestore'
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

//plan selection
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Select from '@mui/material/Select';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';





import FormHelperText from '@mui/material/FormHelperText';

// import GpsFixedIcon from '@mui/icons-material/GpsFixed';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { upload } from '@testing-library/user-event/dist/upload';
import { PhpTwoTone } from '@mui/icons-material';

import { v4 } from 'uuid'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import ListSubheader from '@mui/material/ListSubheader';
import Collapse from '@mui/material/Collapse';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { Toast } from 'react-bootstrap';





export default function Share(data) {




  // const { formData} = location.state;
  const defaultTheme = createTheme(
    {

      text: {
        primary: '#fff',
        secondary: '#fff',
      }

    }
  );

  console.log("shop", data.data.shopName)
  const [open, setOpen] = useState(true);
  // const [data, setData] = useState();
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);


  const handleClick = () => {
    setOpen(!open);
  };



  // useEffect(() => {
  //   // Check if location.state and newFormData are available

  //   const storedFormData = localStorage.getItem('formData');

  //   // Parse the JSON string into a JavaScript object
  //   const parsedFormData = storedFormData ? JSON.parse(storedFormData) : null;
  //   console.log("parse", parsedFormData.slug)

  //   if (location.state?.newFormData || parsedFormData) {
  //     // Retrieve the state data from the URL
  //     const { newFormData } = location?.state;

  //     // Use the newFormData in your component
  //     setData(newFormData);
  //     console.log("form", newFormData);



  //     // Assuming slug is a property in newFormData
  //     const slug = newFormData.slug || parsedFormData.slug || '';
  //     setWebsiteUrl(`https://3pages.in/business/${slug}`);

  //     localStorage.removeItem('formData');

  //   }
  // }, [location]);

  useEffect(() => {
    // Check if location.state and newFormData are available

    // const storedFormData = localStorage.getItem('formData');

    // Parse the JSON string into a JavaScript object
    // const parsedFormData = storedFormData ? JSON.parse(storedFormData) : null;
    // console.log("parse", parsedFormData)

    if (data) {
      // Retrieve the state data from the URL
      // const { newFormData } = location?.state;

      // Use the newFormData in your component
      // setData(parsedFormData);
      // console.log("parseDataSetState", parsedFormData);



      // Assuming slug is a property in newFormData
      const slug = data.data.slug || 'check console';
      setWebsiteUrl(`https://3pages.in/business/${slug}`);

      localStorage.removeItem('formData');

    }
    else {
      console.log('No data found in local storage');
      // Handle the case when no data is found
    }
  }, []);


  console.log("////////ddd", data)

  const handleCopyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(websiteUrl).then(() => {
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 5000);
      });
    }
  };


  return (
    <div className="finalPageBg">
      <ThemeProvider theme={defaultTheme}>
        <div style={{ padding: '20px 10px', alignContent: 'center', alignItems: 'center', display: 'flex' }}>
          <Container component="main" maxWidth="xs" className='upper-layer' style={{ marginTop: '10%' }}>
            <CssBaseline />


            <List
              sx={{ width: '100%', maxWidth: 360, p: 4, pr: 1, pl: 1 }}
              component="nav"
              aria-labelledby="nested-list-subheader"


            >
              <Typography variant='h6' style={{ textAlign: 'center', color: 'white', fontSize: '1.2rem', margin: '10px 0' }}> Congratulations !!! {data ? data.data.shopName : ''}'s Web Page is ready to Share</Typography>

              <ListItemButton >
                {/* <a href={data? `https://ypages-61a6e.web.app/business/${data.shopName}`: ''} style={{textDecoration:'none'}}> */}
                <ListItemIcon>
                  <ContentCopyIcon onClick={handleCopyLink} />
                </ListItemIcon>
                {/* </a> */}
                <a target='_blank' href={data ? `https://3pages.in/business/${data.data.slug}` : ''} style={{ textDecoration: 'none' }}><ListItemText secondary={data ? `https://3pages.in/business/${data.data.slug}` : ''} /></a>
              </ListItemButton>
              <ListItemButton >
                <a target='_blank' href={data ? `https://api.whatsapp.com/send?phone=91${data && data.data.mobileNumber}&text=Hi,Thank you for registering with 3 Pages! Start leveraging our business listing services today and boost your online presence. your link is - ${websiteUrl}` : ''} style={{ textDecoration: 'none', color: 'white' }}>
                  <ListItemIcon>
                    <WhatsAppIcon />
                  </ListItemIcon>
                </a>
                <ListItemText >
                  <a target='_blank' href={data ? `https://api.whatsapp.com/send?phone=91${data && data.data.mobileNumber}&text=Hi,Thank you for registering with 3 Pages! Start leveraging our business listing services today and boost your online presence. your link is - ${websiteUrl}` : ''} style={{ textDecoration: 'none', color: 'white' }}><span style={{ color: 'rgb(0,0,0,0.6d)' }}>Share via Whatsapp</span></a></ListItemText>
              </ListItemButton>
              <Toast show={showSnackbar} onClose={() => setShowSnackbar(false)} delay={2000} autohide>
                <Toast.Body style={{ background: 'black', color: 'white' }}><ContentCopyIcon />  Link copied to clipboard!</Toast.Body>
              </Toast>



              {/* <ListItemButton onClick={handleClick}>
            <ListItemIcon>
                <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Inbox" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                    <StarBorder />
                    </ListItemIcon>
                    <ListItemText primary="Starred" />
                </ListItemButton>
                </List>
            </Collapse> */}
            </List>



          </Container>

        </div>
        <div style={{ textAlign: 'center' }}>
          <Link to={'/executive-form'} style={{ textDecoration: 'none' }}>
            <Button variant='outlined' style={{ color: 'white' }}>Go back to Home</Button>
          </Link>
        </div>

      </ThemeProvider>
    </div>
  )
}