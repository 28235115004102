import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { TextareaAutosize, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';


import { db, storage, } from '../components/firebase'


import { getStorage, ref, getDownloadURL, uploadBytesResumable, uploadBytes, listAll } from "firebase/storage";
// import { addDoc, collection, setDoc, doc, getDoc, getDocs } from 'firebase/firestore'
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

//plan selection
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import QR_IMG from '../Images/qrPay.png'





import FormHelperText from '@mui/material/FormHelperText';

// import GpsFixedIcon from '@mui/icons-material/GpsFixed';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { upload } from '@testing-library/user-event/dist/upload';
import { PhpTwoTone } from '@mui/icons-material';

import { v4 } from 'uuid'
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Confetti from 'react-confetti';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../components/firebase';
import { addDoc, collection, setDoc, doc, getDoc, getDocs } from 'firebase/firestore';
import Share from './Share';




export default function Payment(props) {

  const [showConfetti, setShowConfetti] = useState(false);

  const [user, setUser] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [created, setCreated] = useState();

  const [userData, setUserData] = useState({
    name: '',
    eid: '',
    phoneNumber: ''
  })


  // useEffect(() => {
  //   if (isUploadComplete) {
  //     // Navigate to the share page
  //     navigate('/your-page');
  //   }
  // }, [isUploadComplete]);


  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user);
        const { uid } = user;
        setUser(true)
        console.log("uid >>>>>", uid)
        if (!uid) {
          setIsAuthorized(false);
          setUser(false)

        }

        // Query database to check if this uid is active
        getDoc(doc(db, 'users', uid))
          .then((salesExecRef) => {
            if (salesExecRef.exists()) {
              const { isActive, ename, eid, phoneNumber } = salesExecRef.data();
              if (isActive) {
                setIsAuthorized(true);
                setUserData({
                  name: ename,
                  eid: eid,
                  PhoneNumber: phoneNumber
                })
                console.log("Udata", userData)
              } else {
                setIsAuthorized(false);
              }
            } else {
              setIsAuthorized(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setIsAuthorized(false);
          })
      } else {
        setIsAuthorized(false);
      }
    })
  }, []);

  const navigate = useNavigate();
  const defaultTheme = createTheme();



  const location = useLocation();
  const { formData, uploadedProductImages, uploadedShopImages, uploadedVideos } = location.state || {};

  console.log("formData>>>>>>>>>", formData)
  console.log("product>>>>>>>>>", uploadedProductImages)
  console.log("shop>>>>>>>>>", uploadedShopImages)

  const [newFormData, setnewFormData] = useState(formData)

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (newFormData.images.products.length > 0 && newFormData.images.shop.length > 0) {
        const {
          ownerName,
          shopName,
          slug,
          category,
          address,
          mobileNumber,
          services,
          keywords,
          landmark,
          images,
          transactionId,
        } = newFormData;


        const uploadData = async () => {

          try {


            const existingVendor = await getDoc(doc(db, "vendors", `${slug}`));
            if (existingVendor.exists()) {
              alert(`Vendor's Slug:: ${slug} already exists`);
              // setShowConfetti(true);
              // window.scrollTo({ top: 0, behavior: 'smooth' });

            } else {

              await setDoc(doc(db, "vendors", `${slug}`), newFormData);
              // alert(`Data saved`);
              // alert("working")
              setLoading(false);

              // // Step 1: Get the city collection and cityList document
              // const cityCollectionRef = collection(db, "cities");
              // const cityListDocRef = doc(cityCollectionRef, "cityList");

              // // Step 2: Retrieve the 'cities' array from the cityList document
              // const cityListDocSnap = await getDoc(cityListDocRef);

              // citiesArray.push(newCity);

              // // Step 4: Remove duplicates from the 'cities' array
              // const uniqueCitiesArray = Array.from(new Set(citiesArray));

              // // Step 5: Set the updated 'cities' array back to the cityList document
              // const updatedCityListData = { ...cityListData, cities: uniqueCitiesArray };
              // await setDoc(cityListDocRef, updatedCityListData);

              window.scrollTo({ top: 0, behavior: 'smooth' });
              setShowConfetti(true);
              // navigate('/share', {
              //   state: {
              //     newFormData
              //   },
              // }

              // );



              // Set loading state to false regardless of success or error




              // setTimeout(()=>{

              //     navigate('/share', {
              //       state: {
              //         newFormData                        
              //       },
              //     });


              // },3000)


            }

            // Example validation: checking if all fields are filled
            if (!transactionId) {
              alert("Please fill in all fields.");
              return;
            }
          } catch (error) {
            console.log(error);
          }

        };

        uploadData();

      }
    }
  }, [newFormData.images.products, newFormData.images.shop]);


  // function getCurrentDateAndTime() {
  //   const now = new Date();

  //   return  now ;
  // }

  // const tim = getCurrentDateAndTime();
  // console.log("time",tim)
  // console.log("timeJson",tim.toJSON())


  const handleSubmit = async (e) => {
    e.preventDefault();

    function getCurrentDateAndTime() {
      const now = new Date();
      return now;
    }

    // if (newFormData?.category) {
    //   const categoryRef = doc(db, "_categories", newFormData?.category.id);

    //   setnewFormData((prevData) => ({
    //     ...prevData,
    //     category: categoryRef
    //   }));
    // }

    // const tim = getCurrentDateAndTime();
    // console.log("time",tim)
    // console.log("timeJson",tim.toJSON())

    // Perform validation here before submitting the form
    setLoading(true); // Set loading state to true

    const productImagesRef = ref(storage, `${newFormData.shopName}/products`);
    const shopImagesRef = ref(storage, `${newFormData.shopName}/shop`);
    const shopVideosRef = ref(storage, `${newFormData.shopName}/videos`);

    const productUploadPromises = uploadedProductImages.map((file) => {
      const imageRef = ref(productImagesRef, file.name);
      return uploadBytes(imageRef, file).then(() => {
        return getDownloadURL(imageRef).then((url) => {
          return url;
        });
      });
    });

    const updatedProductImages = await Promise.all(productUploadPromises);

    const shopUploadPromises = uploadedShopImages.map((file) => {
      const imageRef = ref(shopImagesRef, file.name);
      return uploadBytes(imageRef, file).then(() => {
        return getDownloadURL(imageRef).then((url) => {
          return url;
        });
      });
    });
    const updatedShopImages = await Promise.all(shopUploadPromises);



    // Upload shop videos
    const VidUploadPromises = uploadedVideos.map((file) => {
      const videoRef = ref(shopVideosRef, file.name);
      return uploadBytes(videoRef, file).then(() => {
        return getDownloadURL(videoRef).then((url) => {
          return url;
        });
      });
    });

    const updatedShopVideos = await Promise.all(VidUploadPromises);





    try {
      const urls = await Promise.all([...productUploadPromises, ...shopUploadPromises]);
      const updatedProductImages = urls.slice(0, uploadedProductImages.length);
      const updatedShopImages = urls.slice(uploadedProductImages.length);
      const updatedShopVideosUrls = updatedShopVideos;

      setnewFormData((prevData) => ({
        ...prevData,
        images: {
          ...prevData.images,
          products: [...prevData.images.products, ...updatedProductImages],
          shop: [...prevData.images.shop, ...updatedShopImages],
        },
        videos: [...prevData.videos, ...updatedShopVideosUrls],
        ename: userData.name,
        eid: userData.eid,
        createdAt: getCurrentDateAndTime(),
        category: doc(db, "_categories", newFormData?.category?.id)
      }));


      const {
        ownerName,
        shopName,
        category,
        address,
        mobileNumber,
        services,
        keywords,
        landmark,
        images,
        transactionId,
      } = newFormData;

      //   alert("uploading done");
      //   const existingVendor = await getDoc(doc(db, "vendors", `${shopName}`));
      //   if (existingVendor.exists()) {
      //     alert(`Vendor: ${shopName} already exists`);
      //   } else {
      //     await setDoc(doc(db, "vendors", `${shopName}`), newFormData);
      //     alert(`Data saved`);
      //   }

      // Example validation: checking if all fields are filled
      if (!transactionId) {
        alert("Please fill in all fields.");
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setnewFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  console.log("newformData---", newFormData)


  //calculating plan expiry
  const calculateOneYearPeriod = (fromDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(fromDate);
    endDate.setFullYear(endDate.getFullYear() + 1);
    return { startDate, endDate };
  };

  const currentDate = new Date(); // Assuming today's date
  const { startDate, endDate } = calculateOneYearPeriod(currentDate);



  return (

    <>
      {showConfetti ? <Share data={newFormData} />
        :
        <ThemeProvider theme={defaultTheme}>
          <div className='paymentBg' style={{ padding: '30px 10px' }}>
            <Container component="main" maxWidth="xs" className='upper-layer' >
              <CssBaseline />
              <div style={{ margin: '1.5rem 0' }}>
                {/* <h1>Secure Payment</h1> */}
                <div>

                  <Box sx={{ width: '100%', maxWidth: 360 }}>
                    <Box sx={{ my: 3, mx: 2 }}>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          <Typography gutterBottom variant="h4" component="div">
                            Payment
                          </Typography>
                        </Grid>
                        {/* <Grid item>
                        <Typography gutterBottom variant="h6" component="div">
                        {newFormData.plan === "silver" && newFormData.state === 'Andhra Pradesh'? 'INR 365/-' : "INR 500/-" }
                        {newFormData.plan === "gold"? 'INR 799/-' : "" }
                        {newFormData.plan === "platinum"? 'INR 1299/-' : "" }
                        </Typography>
                    </Grid> */}
                      </Grid>
                      <Typography color="text.secondary" variant="body2">
                        By registering as a vendor with 3pages, you can now seize new business opportunities! Experience a secure payment process for seamless transactions
                      </Typography>
                    </Box>
                    <Divider variant="middle" />

                    <Box sx={{ m: 2, mt: 0, }}>
                      <div >
                        <Typography gutterBottom variant="h6">
                          Vendor Details
                        </Typography>
                        <Typography gutterBottom variant="p">
                          Shop Name : {newFormData.shopName}
                        </Typography>
                        <br></br>
                        <Typography gutterBottom variant="p">
                          Owner Name : {newFormData.ownerName}
                        </Typography>
                        <br></br>
                        <Typography gutterBottom variant="p">
                          Slug Name : {newFormData.slug}
                        </Typography>

                        <Typography gutterBottom variant="body1" sx={{ mt: 1 }}>
                          Plan Selected
                        </Typography>
                        <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                          {/* <Chip color="primary" label={newFormData.plan} /> */}
                          <Chip color="success" label={newFormData.plan} />
                          <br></br>
                        </Stack>
                        <Typography gutterBottom variant="p" >
                          Plan Tenure :<br></br> Start date - {startDate.toDateString()} <br></br> End Date - {endDate.toDateString()}
                        </Typography>
                        <br></br>
                      </div>
                    </Box>


                    {/* <Box sx={{ m: 2 }}>
                    
                    
                </Box> */}


                    {/* <Box sx={{ mt: 3, ml: 1, mb: 1 }}>
                    <Button>Add to cart</Button>
                </Box> */}
                  </Box>

                  <Box sx={{ m: 2, ml: 0 }}>
                    <FormHelperText sx={{ ml: 2 }}>Make payment using the QR code below. Your transaction is encrypted end-to-end. You can also use PhonePe, Google Pay, or Paytm for payment.</FormHelperText>

                    <div style={{ textAlign: 'center' }}>
                      <img height={'300px'} width={'80%'} src={QR_IMG} alt="" />
                      <Typography variant="h6" sx={{ textAlign: 'center' }}>(Or Pay using)</Typography>

                    </div>


                    <Box sx={{ width: '100%', maxWidth: 360 }}>

                      <nav aria-label="main mailbox folders">
                        <List>
                          <div style={{ textAlign: 'center' }}>

                          </div>

                          <ListItem disablePadding>
                            <ListItemButton>
                              <ListItemIcon>
                                UPI ID
                              </ListItemIcon>

                              <ListItemText primary="ZOOMTECHNOLGIES@icic" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemButton>
                              <ListItemIcon>
                                Mobile
                              </ListItemIcon>
                              <ListItemText primary="+91 7997995199" />
                            </ListItemButton>
                          </ListItem>

                          <ListItem disablePadding >

                            <ListItemButton>

                              <ListItemIcon>
                                <img width="50" height="50" src="https://img.icons8.com/ios-filled/50/bhim-upi.png" alt="bhim-upi" />
                                <img style={{ margin: '0 20px' }} width="50" height="50" src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/96/external-google-pay-is-the-fast-simple-way-to-pay-online-in-stores-and-more-logo-color-tal-revivo.png" alt="external-google-pay-is-the-fast-simple-way-to-pay-online-in-stores-and-more-logo-color-tal-revivo" />
                                <img width="50" height="50" src="https://img.icons8.com/color/96/phone-pe.png" alt="phone-pe" />
                                <img style={{ margin: '0 20px' }} width="50" height="50" src="https://img.icons8.com/color/96/paytm.png" alt="paytm" />

                              </ListItemIcon>
                            </ListItemButton>
                          </ListItem>


                        </List>
                      </nav>
                      <Divider />

                    </Box>


                  </Box>




                </div>
                <form onSubmit={handleSubmit} >
                  <Box sx={{ mt: 1, mb: 1, m: 2 }}>
                    <h6>
                      Executive Name: {userData.name ? userData.name : ''}
                    </h6>

                    <h6>
                      Executive ID: {userData.eid ? userData.eid : ''}
                    </h6>
                    {/* <TextField
                        name="exeName"
                        label="Executive ID"
                        onChange={handleChange}
                        value={newFormData.exeName}
                        fullWidth
                        width="100%"
                    /> */}
                  </Box>

                  <Box sx={{ mt: 2, mb: 2 }}>
                    <TextField
                      name="transactionId"
                      label="Transaction-Id"
                      onChange={handleChange}
                      value={newFormData.transactionId}
                      fullWidth
                      width="100%"
                    />
                  </Box>

                  {showConfetti && <Confetti height={window.innerHeight} />}
                  <Button fullWidth type="submit" disabled={!newFormData.transactionId} variant="contained" color="success" >
                    Submit
                  </Button>
                  {loading && (
                    <Box
                      sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <CircularProgress color="secondary" />
                    </Box>
                  )}
                </form>
              </div>
            </Container>
          </div>
        </ThemeProvider>

      }
    </>

  )
}