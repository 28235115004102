import {React,useState,useRef,useEffect} from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, createMuiTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import {Alert} from 'react-bootstrap';
import OtpInput from 'react-otp-input';



import LockOpenIcon from '@mui/icons-material/LockOpen';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';


import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Link, useNavigate } from "react-router-dom";

import { useUserAuth } from "../Context/UserAuthContext";

import { MuiOtpInput } from 'mui-one-time-password-input'






export default function SignUpForm(){
    const defaultTheme = createTheme();

    const [otp, setOtp] = useState('');
    const [error,setError] = useState("");
    const [dis,setDis]= useState(false)
    const [flag,setFlag] = useState(false);
    const [confirmObj,setConfimrObj] = useState("");
    const navigate = useNavigate();

    const handleOtpChange = (newValue) => {
        setOtp(newValue)

    }

    const {setUpRecaptcha} = useUserAuth();

    const [num,setnum] = useState('');

    const handleChange = (e) => {
        setnum("+"+e)
    }



    const getOtp = async (e) => {
        e.preventDefault();
        console.log(num.length)
        if(num === "" ||  num === undefined || num.length !== 13)
            return  setError("invalid phone number");
        try{
            setError("")
            setDis(true)

        const res = await setUpRecaptcha(num);
        setConfimrObj(res);
        console.log(res)
        setFlag(true)
        }
        catch(err){
            setError(err.message)
        }

    }

    
    const verifyOtp = async(e) => {
        e.preventDefault();
        if(otp === " " || otp === null) return;

        try{
            setError("")
            const {user} = await confirmObj.confirm(otp);
            const {uid} = user;
              navigate('/executive-form', {
                state: {
                  uid
                },
              });
        }catch(err){
            setError(err.message);
        }
    }

    function Copyright(props) {
        return (
          <Typography variant="body2"  align="center" {...props} style={{color:'white'}}>
            {'Copyright © '}
            <Link color="inherit" to="https://3pages.in/" style={{color:'yellow',textDecoration:'none'}}>
              3 Pages
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
      }

      //otp - autoFill

      const otpRef = useRef(null);

        useEffect(() => {
            const handleClipboardChange = async () => {
            try {
                const clipboardText = await navigator.clipboard.readText();
                if (clipboardText) {
                otpRef.current.value = clipboardText;
                }
            } catch (error) {
                console.error('Error reading OTP from clipboard:', error);
            }
            };

            document.addEventListener('paste', handleClipboardChange);

            return () => {
            document.removeEventListener('paste', handleClipboardChange);
            };
        }, []);




    return(
        <div className="signUpFormBg" style={{}}>

    <ThemeProvider theme={defaultTheme} >
    
        <Container component="main" maxWidth="xs"  >
        <CssBaseline />
        <Box
        className="flex "
            sx={{
            paddingTop:4,
            marginBottom:4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'right',
            }}
        >

        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            }}
      >
        <Avatar sx={{ m: 1, background:'#2B2730', width: 56, height: 56}}>
                <LockOpenIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className="fontMerri" style={{color:'white'}}>
          Executive Signup
        </Typography>
        </Box>
        

        <Box sx={{ mt: 2 }} style={{display : !flag? "block" : "none"}}>
            {error && <Alert variant="danger" >{error}</Alert>}
        </Box>
        <Box sx={{ mb: 2 }} style={{display : !flag? "block" : "none"}}>
        <PhoneInput
        country={'in'}
        value={num}
        onChange={handleChange} 
        inputStyle={{
            height: '50px',
            '& input': {
              height: '100%',
            },
            fontSize:'16px'
          }}  
        minWidth="100%"
        />
        <Box sx={{ mt: 2, mb: 2 }}>
            <div id="recaptcha-container"></div>
        </Box>

            <Box  sx={{ mt: 2, mb: 2 , alignItems:'center'}}>
                <Link to="/" style={{textDecoration:'none'}}>
                    <Button variant="contained" style={{background:'#CD0404', marginRight:'10px'}} >
                        Go Back
                    </Button> 
                </Link>
                    <Button variant="contained"  onClick={getOtp} disabled={dis} style={{color:'black',background:'white'}}>
                        GET OTP
                    </Button>            
            </Box>
        </Box>
        
        <Box sx={{ mt: 2 }}>
            {error && <Alert variant="danger" >{error}</Alert>}
        </Box>

        
        
        <Box sx={{ mt: 2, mb: 2 }} style={{display : flag? "block" : "none"}}>
       
           <div style={{textAlign:'center'}}>
            {/* <MuiOtpInput 
                value={otp}
                ref={otpRef}
                // onChange={(value) => console.log(value)} // Handle OTP change if needed        
                onChange={handleOtpChange}  
                length={6}
                id="borderWhite"
                inputProps={{ pattern: '[0-9]*' }}
                className="otp-input"
                /> */}

            <OtpInput
                
                value={otp}
                onChange={handleOtpChange}
                inputProps={{ type: 'tel', pattern: '[0-9]*', autoComplete: 'one-time-code' }}
                numInputs={6}
                renderSeparator={<span> </span>}
                inputStyle={{ width: '100%', height: '4rem', margin: '0 5px',color:'purple',fontWeight:600 }}
                renderInput={(props) => <input {...props} />}
                />

            </div>

            <Box  sx={{ mt: 2, mb: 2 , alignItems:'center'}}>
           

                {/* <Link to="/">
                    <Button variant="contained" style={{background:'#CD0404', marginRight:'10px'}} >
                        Go Back
                    </Button> 
                </Link> */}
                    <Button variant="contained" color="success" onClick={verifyOtp} style={{color:'black',background:'white'}}>
                        Verify OTP
                    </Button>            
            </Box>

        </Box> 


        </Box>
        <Copyright sx={{ mt: 5 }} />

        </Container>
    </ThemeProvider>        
        </div>
    )
}








