import React, { useState, useEffect } from 'react';
import { Container, ThemeProvider } from 'react-bootstrap';
import { getFirestore } from "firebase/firestore"
import { initializeApp } from "firebase/app"
import { collection, getDocs, setDoc, doc } from 'firebase/firestore';
import { TextField, Autocomplete, MenuItem, Box, FormHelperText, Button, Grid } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';


const firebaseConfig = {
    apiKey: "AIzaSyD6Y9htFDmZw42SaSy0dcXZK0E5at2kYrI",
    authDomain: "ypages-61a6e.firebaseapp.com",
    projectId: "ypages-61a6e",
    storageBucket: "ypages-61a6e.appspot.com",
    messagingSenderId: "928820001491",
    appId: "1:928820001491:web:985419053582e452b7f226",
    measurementId: "G-90GKFCEPK5"
}
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)



const AdminPanel = () => {


    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [title, setTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');


    const [metaKeywords, setMetaKeywords] = useState([]);

    const handleKeywordsChange = (event, newValue) => {
        setMetaKeywords(newValue);
    };


    //Moderate keyword separator

    // const handleKeywordsChange = (event, newValue) => {
    //     if (Array.isArray(newValue)) {
    //         setMetaKeywords(newValue);
    //     } else if (typeof newValue === 'object') {
    //         // If the newValue is an object, it means it was entered manually
    //         // Convert the newValue to an array of keywords
    //         const newKeywords = newValue.inputValue.split(',').map(keyword => keyword.trim());
    //         setMetaKeywords(newKeywords);
    //     }
    // };

    const handlePaste = (event) => {
        const pastedText = event.clipboardData.getData('Text');
        const pastedKeywords = pastedText.split(',').map(keyword => keyword.trim());
        setMetaKeywords([...metaKeywords, ...pastedKeywords]);
        event.preventDefault(); // Prevent the default paste behavior
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleMetaDescriptionChange = (event) => {
        setMetaDescription(event.target.value);
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const colRef = collection(db, '_categories');
                const categoriesSnapshot = await getDocs(colRef);
                const categoryIds = categoriesSnapshot.docs.map(doc => doc.id);
                setCategories(categoryIds);
                console.log("cat", categories)
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const handleSave = async () => {
        try {
            if (!selectedCategory) {
                alert('Please select a category.');
                return;
            }

            const categoryDocRef = doc(db, '_categories', selectedCategory);
            await setDoc(categoryDocRef, {
                seo: {
                    title,
                    metaDescription,
                    metaKeywords
                }
            }, { merge: true });



            // Reload the page after saving
            // window.location.reload(); // You can also use React Router's history object to navigate to another route if you prefer

            // Reset all the values
            setSelectedCategory('');
            setTitle('');
            setMetaDescription('');
            setMetaKeywords([]);

            alert('SEO data saved successfully!');



        } catch (error) {
            console.error('Error saving SEO data:', error);
            alert('Error saving SEO data. Please try again.');
        }
    };

    return (
        <Container maxWidth="md">
            <Box mt={5} mb={7}>

                {/* <ThemeProvider theme={defaultTheme}> */}
                <Container component="main" maxWidth="xs" className='upper-layer' style={{ background: 'white', padding: '70px 50px', backgroundColor: 'transparent' }} >
                    <Box sx={{ '& > :not(style)': { marginBottom: 2 } }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    label="Select Category"
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    variant="outlined"
                                    fullWidth
                                    required
                                >
                                    <MenuItem value="">Choose a Category </MenuItem>
                                    {categories.map((category) => (
                                        <MenuItem key={category} value={category}>
                                            {category}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <FormHelperText>Choose a Category to add SEO data</FormHelperText>

                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Meta Title"
                                    value={title}
                                    onChange={handleTitleChange}
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            {/* <FormHelperText>Add relevant Title</FormHelperText> */}

                            <Grid item xs={12}>

                                <TextField
                                    label="Meta Description"
                                    value={metaDescription}
                                    onChange={handleMetaDescriptionChange}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    required
                                    rows={4}
                                />
                            </Grid>
                            {/* <FormHelperText>Add relevant Description</FormHelperText> */}

                            {/* <label htmlFor="metaKeywords">Meta Keywords:</label> */}
                            <Grid item xs={12}>

                                {/* Moderate keyword separator */}

                                {/* <Autocomplete
                                    multiple
                                    freeSolo
                                    filterOptions={createFilterOptions({
                                        ignoreCase: true,
                                        trim: true,
                                        stringify: (option) => option,
                                    })}
                                    options={[]} // You can provide initial options if needed
                                    value={metaKeywords}
                                    onChange={handleKeywordsChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Meta Keywords"
                                            placeholder="Enter Meta Keywords separated by commas"
                                            onPaste={handlePaste}
                                        />
                                    )}
                                /> */}

                                <Autocomplete
                                    multiple
                                    freeSolo
                                    options={[]} // You can provide initial options if needed
                                    value={metaKeywords}
                                    onChange={handleKeywordsChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Meta Keywords"
                                            placeholder="Enter Meta Keywords"
                                        />
                                    )}
                                />

                                <FormHelperText>Please Press "Enter" or "Next" button, after adding a keyword</FormHelperText>
                            </Grid>
                        </Grid>
                    </Box>

                    <Button variant="contained" style={{ minWidth: '100%' }} onClick={handleSave}>
                        Save
                    </Button>
                </Container>
            </Box>
            <center>
                <h6 style={{ color: 'gray' }}>Built by 🕷 SpyderLabs</h6>
            </center>
        </Container>


    );
};

export default AdminPanel;
