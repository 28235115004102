import React,{ useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, FormHelperText } from "@mui/material";
import Btn from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Logo from '../Images/bgRem.png';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../components/firebase';
import { addDoc, collection, setDoc, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../components/firebase';





export default function Home(){
    const [user, setUser] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [userData,setUserData] = useState({
        name:'',
        eid:'',
        phoneNumber:''
    })

    const navigate = useNavigate();
  
    useEffect(()=>{
        onAuthStateChanged(auth, (user)=>{
          if (user) {
            console.log(user);
            const {uid} = user;
            setUser(true)
            console.log("uid >>>>>",uid)
            if (!uid) {
              setIsAuthorized(false);
              setUser(false)
            
            }
    
            // Query database to check if this uid is active
            getDoc(doc(db, 'users', uid))
            .then((salesExecRef) => {
              if (salesExecRef.exists()) {
                const {isActive,ename,eid,phoneNumber} = salesExecRef.data();
                if (isActive) {
                  setIsAuthorized(true);
                  setUserData({
                    name:ename,
                    eid:eid,
                    PhoneNumber:phoneNumber
                  })
                  console.log("Udata",userData)
                } else {
                  setIsAuthorized(false);
                }
              } else {
                setIsAuthorized(false);
              }
            })
            .catch((err) => {
              console.log(err);
              setIsAuthorized(false);
            })
          } else {
            setIsAuthorized(false);
          }
        })
      }, []);
    
  
    return(
        <>        
        {user  ? 
           <div className="signUpFormBg">
           <div className="flex ">
               <Card className="text-center" style={{boxShadow:'5px 5px 5px 5px rgb(0,0,0,0.5)',background:'rgb(0,0,0,0.5)',border:'none'}}>
                <h6 style={{color:'white',textAlign:'center',marginTop:'20px'}}> Signed in as {userData.name ?userData.name : ''}, e-ID : {userData.eid ?userData.eid : ''}</h6>
                <div style={{display:'flex'}}>
                    <div style={{textAlign:'center',padding:'30px 20px'}}>
                        <button class="btn btn-success" style={{fontSize:'0.9rem',justifyContent:'center',borderRadius:'18px',margin:'0px 10px'}} onClick={()=>{ navigate("/executive-form")}}>Take me to Executive Form</button>
                        <button class="btn btn-danger" style={{fontSize:'0.9rem',justifyContent:'center',borderRadius:'18px',margin:'0px 10px'}} onClick={async ()=>{ await auth.signOut();  window.location.reload(false); localStorage.removeItem('formData'); navigate("/"); }}>Sign Out</button>
                    </div>         
                </div>
                </Card>
            </div>
        </div>
        :
        <div className="signUpFormBg">
        <div className="flex ">
            <Card className="text-center" style={{boxShadow:'5px 5px 5px 5px rgb(0,0,0,0.5)'}}>
            {/* <Card.Header>
            </Card.Header> */}
            <Card.Body>
                <Card.Title>                
                    <img src={Logo} height={100} width={100} alt="" />
                </Card.Title>
                <Card.Text>
                    Exclusively for Executive - 3 Pages
                </Card.Text>
                <Link to ="/signup" style={{textDecoration:'none'}}>
                    <Button style={{textDecoration:'none',background:'black',color:'white'}}  type="submit" variant="contained" color="success" >Executive Signup / Login</Button>
                </Link>
            </Card.Body>
            <Card.Footer className="text-muted">Trouble Logging in? Call <a href="tel:+919973996899"> admin</a></Card.Footer>
            </Card>
                {/* <Button style={{textDecoration:'none',background:'white',color:'black'}} fullWidth type="submit" variant="contained" color="success" >Executive Signup / Login</Button> */}
        </div>
        </div> }
        </>

    )
        

        
    
}