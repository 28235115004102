import React from "react";
import { ExeForm } from "../components/ExeForm";
import SignUpForm from "../components/SignUpForm";

export default function ExePage(){

    return(
        <div className="exeFormBg">
            <ExeForm/>
            {/* <SignUpForm/> */}
        </div>
    )
}