import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Input, TextareaAutosize } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';


import { db, storage, } from './firebase'


import { getStorage, ref, getDownloadURL, uploadBytesResumable, uploadBytes, listAll } from "firebase/storage";
import { addDoc, collection, setDoc, doc, getDoc, getDocs, Firestore } from 'firebase/firestore'
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

//plan selection
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MuiTextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import TwitterIcon from '@mui/icons-material/Twitter';


import FormHelperText from '@mui/material/FormHelperText';

// import GpsFixedIcon from '@mui/icons-material/GpsFixed';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { upload } from '@testing-library/user-event/dist/upload';
import { PhpTwoTone } from '@mui/icons-material';

import { v4 } from 'uuid'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Logo from '../Images/bgRem.png'
import { onAuthStateChanged } from "firebase/auth"
import { auth } from "./firebase"
import MapModal from './MapModal';




export const ExeForm = () => {

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  //imageUrls
  const [uploadedProductImages, setUploadedProductImages] = useState([]);
  const [uploadedShopImages, setUploadedShopImages] = useState([]);

  //Video Upload
  const [uploadedVideos, setUploadedVideos] = useState([]);



  //multiple track list - sub categories
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [servicesState, setservicesState] = useState([]);
  const [coordinates, setCoordinates] = useState({})

  const [isAuthorized, setIsAuthorized] = useState(false);

  const [pincode, setPincode] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [requestController, setRequestController] = useState(null);
  const [isPremium, setIsPremium] = useState(false);

  const [mapOpened, setMapOpened] = useState(false); // Add state for tracking if map has been opened




  const [formData, setFormData] = useState(() => {
    const storedFormData = localStorage.getItem('formData');
    return storedFormData ? JSON.parse(storedFormData) : {
      ownerName: '',
      shopName: '',
      // _/categories/
      category: '',
      categoryRef: '',
      subcategory: '',
      services: '',
      mobileNumber: '',
      keywords: [],
      coordinates: null,
      coordinatesViaAddress: {},
      address: '',
      completeAddress: '',
      addressLine: '',
      landmark: '',
      plan: '',
      images: {
        products: [],
        shop: []
      },
      slug: '',
      status: true,
      state: '',
      city: '',
      pincodeData: '',
      timings: '',
      createdAt: '',
      premium: '',
      socialLinks: {
        'instagram': '',
        'facebook': '',
        'youtube': '',
        'gmail': '',
        'twitter': ''
      },
      videos: []
    };
  });

  const handleSocialLinkChange = (linkType, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      socialLinks: {
        ...prevFormData.socialLinks,
        [linkType]: value,
      },
    }));
  };

  const togglePremium = () => {
    const updatedPremium = !isPremium;
    setIsPremium(updatedPremium);

    // const categoryRef = doc(db, "_categories", selectedCategory.id);

    // alert(categoryRef)


    setFormData(prevData => ({
      ...prevData,
      premium: updatedPremium,
    }));
  };


  // const [formData, setFormData] = useState({
  //   ownerName: '',
  //   shopName: '',
  //   category:'',
  //   subcategory:'',
  //   services:[],
  //   mobileNumber: '',
  //   keywords: [],
  //   coordinates:{},
  //   address: '',
  //   completeAddress:'',
  //   addressLine:'',
  //   landmark:'',
  //   plan:'',
  //   images: {
  //     products: [],
  //     shop: []
  //   },
  //   slug:'',
  //   status:true,
  //   state:'',
  //   city: '',
  //   pincodeData: '',
  //   timings:''

  // });





  const handlePincodeChange = (event) => {
    const enteredPincode = event.target.value;
    setPincode(enteredPincode);

    if (enteredPincode.length === 6) {
      if (requestController) {
        requestController.abort(); // Cancel previous API request
      }

      const controller = new AbortController();
      setRequestController(controller);

      fetchCityAndStateByPincode(enteredPincode, controller);
    } else {
      setSelectedState('');
      setSelectedCity('');
    }
  };

  const fetchCityAndStateByPincode = (pincode, controller) => {
    const apiUrl = `https://api.postalpincode.in/pincode/${pincode}`;

    fetch(apiUrl, { signal: controller.signal })
      .then((response) => response.json())
      .then((data) => {
        if (data && data[0] && data[0].PostOffice && data[0].PostOffice.length > 0) {
          const postOffice = data[0].PostOffice[0];
          setSelectedState(postOffice.State);
          setSelectedCity(postOffice.Division);

          setFormData((prevData) => ({
            ...prevData,
            state: postOffice.State,
            city: postOffice.Division,
            pincodeData: data,
          }));
        } else {
          setSelectedState('');
          setSelectedCity('');
        }
      })
      .catch((error) => {
        console.error('Error fetching city and state:', error);
        setSelectedState('');
        setSelectedCity('');
      });
  };



  const location = useLocation();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user);
        const { uid } = user;
        console.log("uid >>>>>", uid)

        if (!uid) {
          setIsAuthorized(false);
        }

        // Query database to check if this uid is active
        getDoc(doc(db, 'users', uid))
          .then((salesExecRef) => {
            if (salesExecRef.exists()) {
              const { isActive } = salesExecRef.data();
              if (isActive) {
                setIsAuthorized(true);
              } else {
                setIsAuthorized(false);
              }
            } else {
              setIsAuthorized(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setIsAuthorized(false);
          })
      } else {
        setIsAuthorized(false);
      }
    })
  }, []);

  // const catName = [];
  // categories.map((x) => cat.push(x.name));
  const navigate = useNavigate();

  //save to localStorage
  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);


  // useEffect(()=>{
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     coordinates: coordinates,
  //   }));
  // },[formData.address])


  useEffect(() => {
    // Fetch categories from Firestore
    const fetchCategories = async () => {
      try {
        const colRef = collection(db, '_categories');
        const snapshot = await getDocs(colRef);
        const categoriesData = [];
        snapshot.forEach((doc) => {
          categoriesData.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        setCategories(categoriesData);
      } catch (err) {
        throw new Error(err);
      }
    }

    fetchCategories();
  }, []);

  const handleCategoryChange = (event) => {
    // console.log("asdsadas<<<<<",event.target.value.name)

    const selectedCategoryId = event.target.value.id;
    const selectedCategory = categories.find((category) => category.id === selectedCategoryId);


    // const formattedCategory = `_categories/${selectedCategory.name}`;

    if (selectedCategory) {

      setSelectedCategory(selectedCategory.name);
      setSelectedSubcategory(null); // Reset selected subcategory when the category changes
      setSubcategories(selectedCategory.sub || []);


      // const categoryRef = db.doc(`_categories/${selectedCategory.id}`);



    } else {
      setSelectedCategory(null);
      setSelectedSubcategory(null);
      setSubcategories([]);

      // setFormData((prevData) => ({
      //   ...prevData,
      //   categoryRef: null,
      // }));

      // const createCategoryReference = () => {
      //   if (formData.category) {
      //     // formData.category is already a reference, you can directly use it
      //     const categoryRef = formData.category;
      //     // Now you can use categoryRef as needed
      //   }
      // };

    }


    // const createCategoryReference = () => {
    //   if (formData.category) {
    //     const { id, name } = formData.category;
    //     const categoryRef = doc(db, '_categories', id);
    //     // Now you can use categoryRef as needed
    //   }
    // };
  };



  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const defaultTheme = createTheme();

  const [locationInput, setLocationInput] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update shopName field
    if (name === 'shopName') {
      const slug = value.trim().replace(/[\s&.]+/g, '-').toLowerCase();
      setFormData((prevData) => ({
        ...prevData,
        shopName: value,
        slug: slug
      }));
    }

    // Update slug field
    if (name === 'slug') {
      setFormData((prevData) => ({
        ...prevData,
        slug: value
      }));
    }



    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  console.log("dataaaaaaaaaaaaaaaaaaaaaaaaz", formData)

  // const handleLocationClick = async () => {
  //   try {
  //     const position = await getCurrentPosition();

  //     const { latitude, longitude } = position.coords;
  //     const addressViaGps = await getAddressFromCoordinates(latitude, longitude);
  //     setCoordinates({latitude:latitude,longitude:longitude})
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       addressViaGps: addressViaGps,
  //     }));

  //     setLocationInput(addressViaGps);
  //   } catch (error) {
  //     console.log('Error getting location:', error.message);
  //   }
  // };



  // const getCurrentPosition = () => {
  //   return new Promise((resolve, reject) => {
  //     navigator.geolocation.getCurrentPosition(resolve, reject);
  //   });
  // };

  // const getAddressFromCoordinates = async (latitude, longitude) => {

  //   const response = await fetch(
  //     `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
  //   );
  //   const data = await response.json();
  //   const { address } = data;
  //   const formattedAddress = Object.values(address).join(', ');

  //   return formattedAddress;
  // };




  const handleKeywordsChange = (event, value) => {
    setKeywords(value);
    setFormData((prevData) => ({
      ...prevData,
      keywords: value,
    }));
  };

  const handleKeywordsKeyPress = (event) => {
    console.log(event.target.id);
    if ((event.key === 'Enter' || event.target.id === 'add') && event.target.value.trim() !== '') {
      setKeywords((prevKeywords) => [...prevKeywords, event.target.value.trim()]);
      event.target.value = '';
      event.target.focus();

    }
  };


  const handleServicesChange = (event, value) => {
    setservicesState(value);
    setFormData((prevData) => ({
      ...prevData,
      services: value,
    }));
  };

  const handleServicesKeyPress = (event) => {
    if (event.key === 'Enter' && event.target.value.trim() !== '') {
      setservicesState((prevKeywords) => [...prevKeywords, event.target.value.trim()]);
      event.target.value = '';
      event.preventDefault(); // Prevent the form submission
      event.target.focus();
    }
  };

  const [timings, setTimings] = useState([
    { day: 'Weekday', open: '', close: '' },
    { day: 'Weekend', open: '', close: '' },
  ]);

  const handleTimingChange = (index, field, value) => {
    setTimings((prevTimings) => {
      const updatedTimings = [...prevTimings];
      updatedTimings[index] = {
        ...updatedTimings[index],
        [field]: value,
      };
      console.log("tttttttt>", timings)
      // const categoryRef = doc(db, "_categories", selectedCategory.id);

      setFormData((prevData) => ({
        ...prevData,
        timings: updatedTimings,
      }));
      return updatedTimings;
    });
  };



  const handleTimeChange = (index, field) => (event) => {
    handleTimingChange(index, field, event.target.value);
  };

  const formatTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    const parsedHours = parseInt(hours, 10);
    const suffix = parsedHours >= 12 ? 'PM' : 'AM';
    const formattedHours = parsedHours % 12 || 12;
    return `${formattedHours}:${minutes} ${suffix}`;
  };

  const handleSubcategoryInputChange = (event, value) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      setSelectedSubcategories(value ? [value, ...selectedSubcategories] : selectedSubcategories);
      event.target.value = ''; // Clear the input field
      event.target.focus();
    }
  };

  const handleProductImageUpload = (e) => {
    const files = e.target.files;
    setUploadedProductImages([...uploadedProductImages, ...files]);
  };

  const handleShopImageUpload = (e) => {
    const files = e.target.files;
    setUploadedShopImages([...uploadedShopImages, ...files]);
  };




  const handleVideoUpload = (event) => {
    const files = Array.from(event.target.files);
    setUploadedVideos([...uploadedVideos, ...files])
  };

  const handleRemoveVideo = (index) => {
    const newVideos = [...uploadedVideos];
    newVideos.splice(index, 1);
    setUploadedVideos(newVideos);
  };

  const handleRemoveProductImage = (index) => {
    const updatedProductImages = [...uploadedProductImages];
    updatedProductImages.splice(index, 1);
    setUploadedProductImages(updatedProductImages);
  };

  const handleRemoveShopImage = (index) => {
    const updatedShopImages = [...uploadedShopImages];
    updatedShopImages.splice(index, 1);
    setUploadedShopImages(updatedShopImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const {
      ownerName,
      shopName,
      category,
      address,
      mobileNumber,
      services,
      slug,
      plan,
      keywords,
      landmark,
      images
    } = formData;






    // Example validation: checking if all fields are filled
    if (!ownerName || !shopName || !mobileNumber || !slug || !plan || !landmark || category || images.products.length === 0 || images.shop.length === 0) {
      alert('Please fill in all fields.');
      return;
    }

    // If all validations pass, you can submit the form or perform further actions
    console.log('Form data accepted:', formData);

    // Set mapOpened to true when coordinates are obtained
    // setMapOpened(true);

    // handleCloseModal();
  };



  // const [categoryRef, setCategoryRef] = useState(null);


  // const getInitialCategoryRef = async () => {
  //   try {
  //     const Ref = doc(db, '_categories', 'FOOD');
  //     setCategoryRef(Ref);
  //   } catch (error) {
  //     console.error('Error getting category ref:', error);
  //   }
  // };

  // useEffect(() => {
  //   getInitialCategoryRef();
  // }, []); // Run once on mount to get the initial categoryRef

  // useEffect(() => {
  //   if (categoryRef) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       categoryRef: categoryRef,
  //     }));
  //   }
  // }, [categoryRef]); // Run when categoryRef changes



  const handleSubcategoryChange = async (event, value) => {

    // if (formData.category) {
    //   const selectedCategoryId = formData.category.id;

    //   alert(selectedCategoryId)

    //   const categoryRef = (await getDoc(doc(db, '_categories/FOOD'))).get();

    //   console.log(`categoryRef: `, categoryRef.ref);

    //   setFormData((prevData) => ({
    //     ...prevData,
    //     categoryRef: categoryRef,
    //   }));
    // } else {
    //   alert("sdasd")
    // }


    // console.log("ref", categoryRef)




    // if (formData.category) {
    //   const selectedCategoryId = formData.category.id;

    //   alert(selectedCategoryId);

    //   try {
    //     // Assuming you have initialized the 'db' variable correctly
    //     const categoryDoc = await getDoc(doc(db, '_categories/FOOD'));

    //     if (categoryDoc.exists()) {
    //       // Get the DocumentReference
    //       const categoryRef = categoryDoc.ref;

    //       console.log(`categoryRef: `, categoryRef.path);

    //       setFormData((prevData) => ({
    //         ...prevData,
    //         categoryRef: categoryRef, // Use path instead of the full reference
    //       }));
    //     } else {
    //       console.log('Category document does not exist');
    //     }
    //   } catch (error) {
    //     console.error('Error fetching category document:', error);
    //   }
    // } else {
    //   alert('sdasd');
    // }

    // const categoryDoc = await getDoc(doc(db, '_categories/FOOD'));

    // if (categoryDoc.exists()) {
    //   // Get the DocumentReference
    //   const categoryRef = categoryDoc.ref;

    //   // Now you can use categoryRef as needed
    //   console.log(`ref:`, categoryRef.path);  // Example: "_categories/FOOD"
    // } else {
    //   console.log('Document does not exist');
    // }



    setSelectedSubcategories(value);
    setFormData((prevData) => ({
      ...prevData,
      subcategory: value,
    }));






  };

  console.log('Form data:', formData);

  // Save form data in local storage
  localStorage.setItem('formData', JSON.stringify(formData));
  console.log('Form data saved in localStorage');



  async function checkFunction() {

    // Step 1: Get the city collection and cityList document
    const cityCollectionRef = collection(db, "city");
    const cityListDocRef = doc(cityCollectionRef, "cityList");

    // Step 2: Retrieve the 'cities' array from the cityList document
    const cityListDocSnap = await getDoc(cityListDocRef);
    const cityListData = cityListDocSnap.data();
    const citiesArray = cityListData.cities || [];

    // Step 3: Add the city from the formData to the 'cities' array
    const newCity = formData.city;
    citiesArray.push(newCity);

    // Step 4: Remove duplicates from the 'cities' array
    const uniqueCitiesArray = Array.from(new Set(citiesArray));

    // Step 5: Set the updated 'cities' array back to the cityList document
    const updatedCityListData = { ...cityListData, cities: uniqueCitiesArray };
    await setDoc(cityListDocRef, updatedCityListData);
    // const newCity = formData.city;
    console.log("city>", updatedCityListData)
    // alert("sadsa")

    //check if slug already exists
    const existingVendor = await getDoc(doc(db, 'vendors', `${formData.slug}`));
    if (existingVendor.exists()) {
      return false;
    } else {
      return true;
    }


  }

  // const SocialInput = styled(MuiTextField)(({ theme }) => ({
  //   '& .MuiOutlinedInput-root': {
  //     paddingLeft: 0,
  //   },
  //   '& .MuiInputAdornment-root': {
  //     backgroundColor: theme.palette.divider,
  //     padding: '21px 12px',
  //     borderTopLeftRadius: theme.shape.borderRadius + 'px',
  //     borderBottomLeftRadius: theme.shape.borderRadius + 'px',
  //   },
  // }));


  // CoordUsingRevGeoCoding();

  const check = async (e) => {
    try {
      const isFunctionValid = await checkFunction();

      if (isFunctionValid) {
        // Redirect to payment page with state
        navigate('/payment', {
          state: {
            formData,
            uploadedProductImages,
            uploadedShopImages,
            uploadedVideos
          },
        });
      }
      else {
        // Show alert and stay on the same page
        alert(`Vendor's Slug: ${formData.slug} slug already exists`);
      }
    } catch (error) {
      console.error('Error in check function:', error);
      alert('An error occurred. Please try again.');
    }
  };





  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);

  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };





  if (isAuthorized) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <div style={{ padding: '20px 10px' }}>
          <Container component="main" maxWidth="xs" className='upper-layer' style={{ background: 'white' }} >
            <CssBaseline />
            <Box
              sx={{
                marginTop: 4,
                marginBottom: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'right',
              }}
            >

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {/* <Avatar sx={{ m: 1, bgcolor: 'success.dark', width: 56, height: 56}}> */}
                {/* <HowToRegIcon /> */}
                <img height={90} width={90} src={Logo} alt="" />
                {/* </Avatar> */}
                <Typography component="h1" variant="h5" className="fontMerri">
                  Register Vendor
                </Typography>
              </Box>
              <form onSubmit={handleSubmit} >
                <Box sx={{ mt: 2, mb: 2 }}>

                  <TextField
                    name="ownerName"
                    label="Owner Name"
                    value={formData.ownerName}
                    onChange={handleChange}
                    autoComplete={false}
                    required
                    fullWidth
                    autoFocus
                  />
                </Box>

                <Box sx={{ mt: 2, mb: 2 }}>
                  <TextField
                    name="shopName"
                    label="Shop Name"
                    value={formData.shopName}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Box>

                <Box sx={{ mt: 2, mb: 2 }}>
                  <TextField
                    name="slug"
                    label="Slug"
                    value={formData.slug}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Box>

                <Box sx={{ mt: 2, mb: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">category</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="category"
                      value={formData.category}
                      label="Category*"
                      required
                      onChange={(e) => {
                        handleChange(e);
                        handleCategoryChange(e);
                      }}
                      overflowY="scroll"
                      MenuProps={MenuProps}
                    >
                      {categories.map((v, i) => (
                        <MenuItem key={i} value={v}>
                          {v.name}
                        </MenuItem>
                      )
                      )
                      }
                    </Select>
                  </FormControl>
                </Box>


                {selectedCategory && (
                  <Box sx={{ mt: 2, mb: 2 }}>
                    <Autocomplete
                      multiple
                      freeSolo
                      required
                      options={subcategories}
                      value={Array.isArray(formData.subcategory) ? formData.subcategory : []}
                      onChange={handleSubcategoryChange}
                      onKeyDown={handleSubcategoryInputChange}
                      renderInput={(params) => (
                        <TextField {...params} label="Subcategory" placeholder="Select subcategories" type="search"
                        // onDoubleClick={e => SubsHandleKeywordsDoubleClick(e,params.inputProps.value)}
                        />
                      )}
                    />
                  </Box>
                )}

                <Box sx={{ mt: 2, mb: 2 }}>
                  <Autocomplete
                    name="keywords"
                    multiple
                    freeSolo
                    autocomplete="false"
                    options={servicesState} // Pass the keywords array as options
                    value={formData.services}
                    onChange={handleServicesChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Services" placeholder='Enter the Service(s) Here' onKeyDown={handleServicesKeyPress} type="search"
                      // onDoubleClick={(e) => ServicesHandleKeywordsDoubleClick(e,params.inputProps.value)}
                      />
                    )}

                  />
                  <FormHelperText>Add all the services provided by the Vendor</FormHelperText>
                </Box>





                <TextField
                  name="mobileNumber"
                  label="Mobile Number "
                  value={formData.mobileNumber.slice(0, 10)}
                  onChange={handleChange}
                  required
                  fullWidth
                />
                <FormHelperText>Preferably, Add WhatsApp Number</FormHelperText>


                <Box sx={{ mt: 2, mb: 2 }}>
                  <Autocomplete
                    name="keywords"
                    multiple
                    freeSolo
                    autocomplete="false"
                    options={keywords} // Pass the keywords array as options
                    value={formData.keywords}
                    onChange={handleKeywordsChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Secondary Mobile Numbers (Optional)" placeholder='Enter your mobile number and Press Enter/OK' onKeyDown={handleKeywordsKeyPress} type="search"
                      />
                    )}

                  />
                  {/* <Button id="add" onClick={(e) => handleKeywordsKeyPress(e)}>+</Button> */}
                  <FormHelperText>Can Add up to 3 additional mobile numbers</FormHelperText>
                </Box>

                {/* <Box sx={{ mt: 2, mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <LocationOnIcon id="gpsIcon"  onClick={handleLocationClick} />

                    {locationInput? <FormHelperText style={{margin:'0 10px',color:'black'}}>{locationInput || formData.address}</FormHelperText> : <FormHelperText style={{margin:'0 10px',color:'black'}}>Click GPS Icon to fetch exact Location</FormHelperText> }
                  </Box>

                </Box> */}
                <Box sx={{ mt: 2, mb: 2 }}>
                  <TextField
                    label="Pincode"
                    value={pincode}
                    onChange={handlePincodeChange}
                    // onBlur={handlePincodeBlur}
                    style={{ width: '100%' }}
                    inputProps={{ maxLength: 6 }}
                  />
                </Box>



                <div style={{ display: 'flex', alignItems: 'center' }}>




                  {pincode && (
                    <FormControl style={{ width: '100%', marginRight: '16px' }}>
                      <InputLabel id="state-label" disabled={pincode}>
                        State
                      </InputLabel>
                      <Select
                        labelId="state-label"
                        id="state-select"
                        value={selectedState}
                        disabled={true}
                      >
                        <MenuItem value={selectedState}>{selectedState}</MenuItem>
                      </Select>
                    </FormControl>
                  )}

                  {pincode && (
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="city-label" disabled={pincode}>
                        City
                      </InputLabel>
                      <Select
                        labelId="city-label"
                        id="city-select"
                        value={selectedCity}
                        disabled={true}
                      >
                        <MenuItem value={selectedCity}>{selectedCity}</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </div>


                <Box sx={{ mt: 2, mb: 2 }}>
                  <Button onClick={handleOpenModal} variant="outlined" startIcon={<AddLocationAltIcon />}>Open Map</Button>
                  <MapModal
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                    onSubmit={handleSubmit}
                    formData={formData}
                    setFormData={setFormData}
                    disabled={mapOpened} // Disable the button if mapOpened is true
                  />
                </Box>

                {formData.coordinates && (
                  <FormControl style={{ width: '100%' }}>
                    <FormHelperText>{`latitude = ${formData.coordinates.latitude}, longitude = ${formData.coordinates.longitude}  `}</FormHelperText>
                  </FormControl>
                )}

                <Box sx={{ mt: 2, mb: 2 }}>
                  <TextField
                    name="completeAddress"
                    required
                    label="Complete Address"
                    value={formData.completeAddress}
                    onChange={handleChange}
                    fullWidth
                    width="100%"
                  />
                </Box>

                <Box sx={{ mt: 2, mb: 2 }}>
                  <TextField
                    name="addressLine"
                    required
                    label="Address Line"
                    value={formData.addressLine}
                    onChange={handleChange}
                    fullWidth
                    width="100%"
                  />
                </Box>

                <Box sx={{ mt: 2, mb: 2 }}>
                  <TextField
                    name="landmark"
                    label="LandMark"
                    required
                    value={formData.landmark}
                    onChange={handleChange}
                    fullWidth
                    width="100%"
                  />
                </Box>

                <Box sx={{ mt: 1, mb: 2 }}>

                  {timings.map((timing, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: index === 0 ? 0 : 2 }}>
                      <TextField
                        label={`${timing.day} Open Time`}
                        value={timing.open}
                        onChange={handleTimeChange(index, 'open')}
                        fullWidth
                        type="time" // Set input type to "time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: {
                            step: 300, // 5 minutes step
                          }
                        }}
                        sx={{ flex: 1, mr: 2 }}
                      />

                      <TextField
                        label={`${timing.day} Close Time`}
                        value={timing.close}
                        onChange={handleTimeChange(index, 'close')}
                        fullWidth
                        type="time" // Set input type to "time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: {
                            step: 300, // 5 minutes step
                          }
                        }}
                        sx={{ flex: 1 }}
                      />
                    </Box>
                  ))}
                </Box>


                {/* plan selection */}

                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label" >Choose your Plan</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="plan"
                    onChange={handleChange}
                  >
                    <FormControlLabel value="silver" control={<Radio />}
                      label={
                        <VerifiedUserIcon style={{ color: '#808080', fontSize: '2rem' }} />
                      }
                    />
                    <FormControlLabel value="gold" control={<Radio />}
                      label={
                        // <Avatar sx={{ m: 1, bgcolor: '#FFD700'}}>
                        //   <VerifiedUserIcon />
                        // </Avatar>
                        <VerifiedUserIcon style={{ color: '#FFD700', fontSize: '2rem' }} />
                      }
                    />
                    <FormControlLabel value="platinum" control={<Radio />}
                      label={
                        // <Avatar sx={{ m: 1, bgcolor: 'black'}}>
                        //   <VerifiedUserIcon />
                        // </Avatar>
                        <VerifiedUserIcon style={{ color: 'black', fontSize: '2rem' }} />
                      }
                    />

                  </RadioGroup>
                </FormControl>
                <FormHelperText>You can choose among Silver, Gold and Platinum Plans</FormHelperText>




                <div style={{ margin: '10px 0px' }}>
                  <ToggleButtonGroup
                    value={isPremium}
                    name="premium"
                    exclusive
                    onChange={togglePremium}
                    aria-label="premium toggle"
                  >
                    <ToggleButton value={true} aria-label="premium">
                      Premium
                    </ToggleButton>
                    <ToggleButton value={false} aria-label="not premium">
                      Not Premium
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <FormHelperText><span style={{ fontWeight: '700' }}>Status:</span> {isPremium ? 'Premium' : 'Not Premium'}</FormHelperText>
                </div>

                {isPremium &&
                  <Box sx={{ mt: 1, mb: 2 }}>


                    <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2, mb: 2 }}>
                      <TextField
                        placeholder="Instagram"
                        type="url"
                        size='small'
                        value={formData.socialLinks.instagram}
                        onChange={(e) =>
                          handleSocialLinkChange('instagram', e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <InstagramIcon />

                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
                      <TextField
                        placeholder="Facebook"
                        size='small'
                        type="url"
                        value={formData.socialLinks.facebook}
                        onChange={(e) =>
                          handleSocialLinkChange('facebook', e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FacebookIcon />

                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2, mb: 2 }}>
                      <TextField
                        placeholder="YouTube"
                        type="url"
                        size='small'
                        value={formData.socialLinks.youtube}
                        onChange={(e) =>
                          handleSocialLinkChange('youtube', e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <YouTubeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2, mb: 2 }}>
                      <TextField
                        placeholder="Gmail"
                        type="url"
                        size='small'
                        value={formData.socialLinks.gmail}
                        onChange={(e) =>
                          handleSocialLinkChange('gmail', e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MarkunreadIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2, mb: 2 }}>
                      <TextField
                        placeholder="Twitter"
                        type="url"
                        size='small'
                        value={formData.socialLinks.twitter}
                        onChange={(e) =>
                          handleSocialLinkChange('twitter', e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <TwitterIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>



                    <Box >
                      <label htmlFor="videoInput">
                        <Button
                          variant="outlined"
                          component="span"
                          startIcon={<CloudUploadOutlinedIcon />}
                        >
                          Choose Video
                        </Button>
                        <input
                          id="videoInput"
                          type="file"
                          accept="video/*"
                          onChange={handleVideoUpload}
                          style={{ display: 'none' }}
                        />
                      </label>
                      <FormHelperText>Upload videos</FormHelperText>

                      <div>
                        {uploadedVideos.map((video, index) => (
                          <div key={index} style={{ position: 'relative', margin: '5px' }}>
                            <video
                              controls
                              src={URL.createObjectURL(video)}
                              style={{ maxWidth: '100%', marginBottom: '5px' }}
                            />
                            <CloseIcon
                              style={{
                                position: 'absolute',
                                top: '0px',
                                right: '0px',
                                color: '#CD1818',
                                cursor: 'pointer',
                                fontSize: '1.5rem',
                                background: 'rgba(0, 0, 0, 0.3)',
                              }}
                              onClick={() => handleRemoveVideo(index)}
                            />
                          </div>
                        ))}
                      </div>
                    </Box>

                  </Box>
                }




                {/* collect shop and product Pictures */}

                {/* //product images */}

                <Box sx={{ mt: 2, mb: 2 }}>
                  <label htmlFor="productImagesInput">
                    <Button variant="outlined" disabled={uploadedProductImages.length >= 5} component="span" startIcon={<CloudUploadOutlinedIcon />}>
                      Choose Product Images
                    </Button>

                    <input
                      id="productImagesInput"
                      type="file"
                      multiple
                      onChange={handleProductImageUpload}
                      style={{ display: 'none' }}
                    />
                  </label>

                  <FormHelperText>Upload product images upto 5</FormHelperText>

                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {uploadedProductImages.map((image, index) => (
                      <div key={index} style={{ marginRight: '10px', position: 'relative', margin: '5px' }}>
                        <img src={URL.createObjectURL(image)} height={70} width={70} style={{ border: '1px solid black', boxShadow: '5px 5px grey' }} alt={`Product${index}`} />
                        <CloseIcon
                          style={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                            color: '#CD1818',
                            cursor: 'pointer',
                            fontSize: '1.5rem',
                            background: 'rgb(0,0,0,0.3)'
                          }}
                          onClick={() => handleRemoveProductImage(index)}
                        />
                      </div>
                    ))}
                  </div>

                </Box>

                {/*video Upload */}
                <Box sx={{ mt: 2, mb: 2 }}>

                </Box>

                {/* //shop images */}

                <Box sx={{ mt: 2, mb: 2 }}>
                  <label htmlFor="shopImagesInput">
                    <Button
                      variant="outlined"
                      component="span"
                      disabled={uploadedShopImages.length >= 5}
                      startIcon={<CloudUploadOutlinedIcon />}
                    >
                      Choose Shop Images
                    </Button>
                    <input
                      id="shopImagesInput"
                      type="file"
                      multiple
                      onChange={handleShopImageUpload}
                      style={{ display: 'none' }}
                    />
                  </label>
                  <FormHelperText>Upload Shop images upto 5</FormHelperText>

                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {uploadedShopImages.map((image, index) => (
                      <div key={index} style={{ marginRight: '10px', position: 'relative', margin: '5px' }}>
                        <img src={URL.createObjectURL(image)} height={70} width={70} style={{ border: '1px solid black', boxShadow: '5px 5px grey' }} alt={`ProductImage${index}`} />
                        <CloseIcon
                          style={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                            color: '#CD1818',
                            cursor: 'pointer',
                            fontSize: '1.5rem',
                            background: 'rgb(0,0,0,0.3)',
                          }}
                          onClick={() => handleRemoveShopImage(index)}
                        />
                      </div>
                    ))}
                  </div>

                </Box>


                <Button fullWidth type="submit" variant="contained" color="success" >
                  <Link style={{ textDecoration: 'none', color: 'white' }} onClick={check} state={{ formData, uploadedProductImages, uploadedShopImages, uploadedVideos }} >
                    Verify and Proceed to Payment
                  </Link>
                </Button>
              </form>
              {/* </div>
      </div> */}
            </Box>
          </Container>
        </div>
      </ThemeProvider>
    );
  } else {

    return (
      <div style={{ display: 'flex', minHeight: '100vh', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center', padding: '0px 50px' }}>
        <center >
          <h4 style={{ color: '#fff' }}>Not authorized. Please contact Admin.</h4>
          <Button onClick={() => { window.location.reload(false) }} style={{ background: '#fff', color: 'black', borderRadius: '30px', padding: '7px 15px', marginTop: '10px' }}>Retry</Button>
        </center>
      </div>
    )
  }
};
