import React ,{createContext, useContext, useEffect, useState} from "react";

import {
     signOut,
     onAuthStateChanged,
     RecaptchaVerifier,
     signInWithPhoneNumber

} from 'firebase/auth'
import { auth } from "../components/firebase";


const UserAuthContext = createContext();

export function UserAuthContextProvider({children}){

    const [user,setuser]= useState();

    function setUpRecaptcha(number){
        const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
        recaptchaVerifier.render();
        
        return signInWithPhoneNumber(auth,number,recaptchaVerifier)
    }

    function logOut(){
        return signOut(auth)
    }

    useEffect(()=>{
        const unsubscribe = onAuthStateChanged( auth, (currenUser) => {
            setuser(currenUser);
        });
        return () => {
            unsubscribe();
        }
    },[])

  

    return(
       <UserAuthContext.Provider value={{user,logOut,setUpRecaptcha}}>
            {children}
       </UserAuthContext.Provider>
    )
}



export function useUserAuth(){
    return useContext(UserAuthContext)
}